import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import NotFound from "../pages/notfound";
import PoliciesPage from "../pages/policies";
import Aboutus from "../pages/aboutus";


function AppRouter() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/policies" element={<PoliciesPage />} />
        <Route path="/aboutus" element={<Aboutus />} />
      
      </Routes>
    </>
  );
}

export default AppRouter;
