import "./App.css";
import AppRouter from "./router/appRouter";
import 'font-awesome/css/font-awesome.min.css';


function App() {
  return <AppRouter />;
}

export default App;
