// import "./styles.css";
import axios from "axios";
import { useState } from "react";

export default function Form() {
  const [formStatus, setFormStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
    platform: "",
    file: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;

    if (!query.name || !query.email || !query.message) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(
        "https://getform.io/f/ad953b76-9afb-4c45-a761-f731698aba83",
        formData,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        setFormStatus(true);
        setQuery({
          name: "",
          email: "",
          message: "",
        });
        setLoading(false);
        setError("");
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="form flex items-center">
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Full name"
            value={query.name}
            onChange={handleChange}
            className="text-black"
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={query.email}
            onChange={handleChange}
            className="text-black"
          />
        </div>
        <div className="form-group">
          <textarea
            type="text"
            name="message"
            placeholder="message"
            value={query.message}
            onChange={handleChange}
          ></textarea>
        </div>
        {/* <input type="file" name="file" onChange={handleFileChange} /> */}
        <button className="send-form-button bg-[#fff] text-[#000] mt-4" type="submit">
          Send
        </button>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {formStatus && <p>Message sent.</p>}
      </form>
    </div>
  );
}
