import { Carousel } from "flowbite-react";

function NotFound() {
  return (
    <div>
      <h1>Not Found</h1>
      <Carousel />
    </div>
  );
}

export default NotFound;
