import React from "react";
import SearchBar from "./searchBar";
import CategoryDropdown from "./categoryDropdown";

function Header() {
  return (
    <div className="w-screen flex flex-col bg-background">
      <div className="container flex mx-auto bg-background">
        <div className="bg-background">
          <a href="/" className="header-logo">
            Meilleur Prix Amazon
          </a>
        </div>
        <SearchBar />
      </div>
      <div className="flex flex-wrap justify-center h-12 w-screen bg-[#3e4a61]">
        <div className="flex items-center justify-center  ">
          <CategoryDropdown />
        </div>
        <div className="flex items-center justify-center ">
          <a
            href="/policies"
            className="block text-button-text text-bold p-2 hover:bg-white shadow-md hover:text-[#000]"
          >
            Privacy Policy
          </a>
        </div>
        <div className="flex items-center justify-center  ">
          <a
            href="/aboutus"
            className="block text-button-text p-2 hover:bg-white shadow-md hover:text-[#000]"
          >
            About us
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
