"use client";
import { Carousel } from "flowbite-react";

export default function DefaultCarousel() {
  return (
    <Carousel>
      <img className="relative z-40 " alt="..." src="/images/pic-1.jpg" />
      <img className="relative z-40 " alt="..." src="/images/pic-2.jpg" />
      <img className="relative z-40 " alt="..." src="/images/pic-3.jpg" />
      <img className="relative z-40 " alt="..." src="/images/pic-4.jpg" />
      <img className="relative z-40 " alt="..." src="/images/pic-5.jpg" />
    </Carousel>
  );
}
