import Header from "../components/header";

function PolicySection({ title, data }) {
  return (
    <div>
      <h2 className="text-xl font-semibold my-4">{title}</h2>
      {typeof data === "object" ? (
        <ul>
          {Object.entries(data).map(([subTitle, subData], index) => (
            <li key={index}>
              <strong className="font-semibold my-2">
                {subTitle || subData}
              </strong>

              <ul>
                {Array.isArray(subData)
                  ? subData.map((item, i) => <li key={i}>{item}</li>)
                  : null}
              </ul>
            </li>
          ))}
        </ul>
      ) : (
        <p>{data}</p>
      )}
    </div>
  );
}

function PoliciesPage() {
  const policyData = [
    {
      title: "1. Data Collection",
      data: {
        "1.1 Information You Provide": [
          "Your Email address",
          "Your name/username",
          "Any other information you choose to share",
        ],
        "1.2 Automatic Data Collection": [
          "Log information (e.g., IP address, browser type)",
          "Usage data (e.g., pages visited, actions taken)",
          "Cookies and similar technologies (Cookie Policy for details)",
        ],
      },
    },
    {
      title: "2. Data Use",
      data: {
        "2.1 We utilize your information to enhance your experience, provide personalized content, communicate with you, analyze usage trends, and meet legal obligations.":
          [
            "To provide, maintain, and improve our application",
            "To personalize your experience and offer tailored content",
            "To communicate with you, including responding to inquiries",
            "To analyze usage patterns and trends",
            "To comply with legal obligations",
          ],
      },
    },
    {
      title: "3. Data Sharing",
      data: {
        "3.1 Third-Party Services": [
          "We may share your data with third-party service providers that assist us in delivering our services and improving the app. These providers are bound by confidentiality agreements and are not authorized to use your data for any purpose other than what is agreed upon.",
        ],

        "3.2 Legal Compliance": [
          "We may disclose your data as required by law, such as in response to a subpoena or other legal request.",
        ],
      },
    },
    {
      title: "4. Data Security",
      data: "We implement reasonable technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, or destruction.",
    },
    {
      title: "5. Data Retention",
      data: "We retain your data only for as long as necessary to fulfill the purposes outlined in this policy or as required by applicable laws and regulations.",
    },
    {
      title: "6. Your Rights",
      data: {
        "6.1 You have the following rights regarding your data:": [
          "Access:You can request access to the personal data we hold about you.",
          "Rectification: You can correct inaccuracies in your personal data",
          "Deletion:You can request the deletion of your personal data.",
          "Objection: You can object to the processing of your data for certain purposes.",
          "Data Portability: You can request a copy of your data in a machine-readable format.",
        ],
      },
    },
    {
      title: "7. Contact Us",
      data: "If you have any questions, concerns, or requests regarding your data or this policy, please contact us at [Contact Email].",
    },
    {
      title: "8. Changes to this Policy",
      data: "We may update this User Data Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on our website, and the 'Last Updated' date will be revised.",
    },
    // Add more sections as needed
  ];

  return (
    <div>
      <Header />
      <div className="flex justify-center bg-[#fff] text-background">
        <div className="m-8 max-w-5xl">
          <p className="text-4xl font-sans font-semibold">
            Welcome to Meilleur Prix Amazon User Data Policy{" "}
          </p>
          <div>
          
            {policyData.map((section, index) => (
              <PolicySection
                key={index}
                title={section.title}
                data={section.data}
              />
            ))}
            <p>
              By using MPA, you consent to the terms of this User Data Policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PoliciesPage;
