import Header from "../components/header";

function ShowData({ title, description }) {
  return (
    <ul>
      <li className="text-xl my-4 font-semibold">{title}</li>
      {Array.isArray(description) ? (
        <ol>
          {description.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
      ) : (
        <li>{description}</li>
      )}
    </ul>
  );
}

function Aboutus() {
  const aboutusData = [
    {
      title: "Our Mission",
      description:
        "At MeilleurPrixAmazon, our mission is clear: to empower consumers like you with the knowledge and tools needed to make smart shopping decisions. We're committed to leveling the playing field and ensuring that you never pay more than necessary for the products you love.",
    },
    {
      title: "How We Work",
      description:
        "Our cutting-edge technology constantly monitors the Amazon marketplace, tracking prices of thousands of products across various categories. We employ sophisticated algorithms and data analytics to identify fluctuations in prices, allowing us to pinpoint the best deals and hidden discounts.",
    },
    {
      title: "Our Unique Approach",
      description:
        "What sets MeilleurPrixAmazon apart is our unique approach to solving the dynamic pricing dilemma. Instead of settling for the first price you see, we provide you with multiple options from different Amazon stores. These options are carefully curated to include reliable and highly-rated sellers, ensuring you get the quality you deserve at the best price available.",
    },
    {
      title: "Key Features",
      description: [
        "Price Comparison: Our platform compares prices from multiple Amazon stores, helping you find the most cost-effective option for your desired product. ",
        "Real-time Updates: We continuously monitor prices and update our recommendations to reflect the latest price drops and discounts. ",
        "Price Alerts: Set up price alerts to receive notifications when your desired product reaches your target price.",
      ],
    },
    {
      title: "Why Choose MeilleurPrixAmazon?",
      description: [
        "Save Money: We help you find the same products you love at a lower price, saving you hard-earned cash.",
        "Transparency: We believe in transparency, providing you with all the information you need to make the right purchase decision.",
        "Effortless Shopping: Our user-friendly interface makes it easy to compare prices and make purchases hassle-free.",
        "Stay Informed: With real-time updates and price alerts, you'll never miss out on a great deal again.",
        "Reliability: We only recommend reputable sellers, ensuring you receive quality products every time.",
      ],
    },
    // {
    //   title: "Join Us Today!",
    //   description:
    //     "Don't let dynamic pricing deter you from enjoying the convenience of online shopping. Join MeilleurPrixAmazon today and take control of your shopping experience. Start saving money while enjoying the same top-quality products you trust from Amazon. Let's revolutionize the way you shop online!",
    // },
  ];


  return (
    <div>
      <Header />

      <div className="flex justify-center bg-[#fff] text-background">
        <div className="m-8 max-w-5xl">
          <p className="text-4xl font-sans font-semibold">
            About us Meilleur Prix Amazon{" "}
          </p>
          <div>
            <h1 className="text-xl my-4">
              Welcome to MeilleurPrixAmazon, your ultimate solution to conquer
              the ever-changing world of online shopping! We understand the
              frustration of dynamic pricing on popular e-commerce platforms
              like Amazon, where the cost of your favorite products can
              fluctuate like a rollercoaster. That's why we're here to
              revolutionize your online shopping experience.
            </h1>

            {aboutusData.map((data, index) => (
              <ShowData
                key={index}
                title={data.title}
                description={data.description}
              />
            ))}

            <ul>
              <li className="text-xl my-4 font-semibold">
                <button className="bg-background text-white w-40 h-10 rounded-md">
                  <a className="text-white" href="/register">
                    Join Us Today!
                  </a>
                </button>
              </li>

              <li>
                Don't let dynamic pricing deter you from enjoying the
                convenience of online shopping. Join MeilleurPrixAmazon today
                and take control of your shopping experience. Start saving money
                while enjoying the same top-quality products you trust from
                Amazon. Let's revolutionize the way you shop online!
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
