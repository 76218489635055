import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBabyCarriage,
  faBookOpen,
  faClock,
  faCouch,
  faGamepad,
  faHeadphones,
  faLaptop,
  faMobileScreen,
  faNotesMedical,
  faPersonRunning,
  faShirt,
  faShower,
  faSpoon,
  faSuitcase,
  faTv,
  faCar,
  faPalette,
  faHouseSignal,
  faPaw,
  faSoap,
  faPerson,
  faPersonDress,
  faChild,
  faIndustry,
  faWrench,
  faScrewdriverWrench,
  faSun,
  faWindowRestore,
  faCat,
  faChildDress
} from "@fortawesome/free-solid-svg-icons";

const categoriesData = [
  { icon: faHeadphones, name: "Image & Sound" },
  { icon: faBabyCarriage, name: "Baby" },
  { icon: faPersonRunning, name: "Sport" },
  { icon: faShirt, name: "Clothes" },
  { icon: faClock, name: "Watches" },
  { icon: faTv, name: "Movies & TV" },
  { icon: faBookOpen, name: "Books" },
  { icon: faCouch, name: "Furniture" },
  { icon: faGamepad, name: "Games" },
  { icon: faLaptop, name: "Laptops" },
  { icon: faMobileScreen, name: "Phones" },
  { icon: faNotesMedical, name: "Health & Househeld" },
  { icon: faShower, name: "Bathroom" },
  { icon: faSpoon, name: "Kitchen" },
  { icon: faSuitcase, name: "Luggage" },
  { icon: faCar, name: "Automotive" },
  { icon: faPalette, name: "Art & Crafts" },
  { icon: faHouseSignal, name: "Smart Home" },
  { icon: faPaw, name: "Pet Supplies" },
  { icon: faSoap, name: "Beauty & Personal care" },
  { icon: faPerson, name: "Men's Wears" },
  { icon: faPersonDress, name: "Females's Wears" },
  { icon: faChild, name: "Boys's Wears" },
  { icon: faChildDress, name: "Girls's Wears" },
  { icon: faIndustry, name: "Industry" },
  { icon: faWrench, name: "Hydraulics & Plumbing" },
  { icon: faScrewdriverWrench, name: "Home Improvment" },
  { icon: faSun, name: "Outdoor" },
  { icon: faWindowRestore, name: "Software" },
  { icon: faCat, name: "Toys" },
 
];

function Categories() {
  return (
    <div>
      <div className="flex justify-center items-center grid grid-cols-2 xm:grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-1 xm:p-5 sm:p-5 md:10 ">
        {categoriesData.map((category, index) => (
          <div
            key={index}
            className="h-36 w-36 bg-white p-4 m-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 flex flex-col items-center justify-center-white p-4 m-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 "
          >
            <FontAwesomeIcon
              icon={category.icon}
              style={{ color: "#0e172c", height: 80, width: 80 }}
            />
            <p className="text-paragraph font-bold text-center mt-2">
              {category.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Categories;
