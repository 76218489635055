import React, { useState } from "react";
import SearchIcon from "../icons/SearchIcon";

function SearchBar() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
   
  };

  return (
    <div className="container flex justify-center items-center  ">
      <input
        className="h-8 p-1 w-11/12 rounded-sm bg-input-background focus:border-borders"
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleInputChange}
      />
      <button className="bg-button h-8 p-1 w-8 rounded-r-md" onClick={handleSearch}><SearchIcon /></button>
    </div>
  );
}

export default SearchBar;
