import Carousel from "../components/carousel";
import Header from "../components/header";
import Footer from "../components/footer";
import Categories from "../components/categories";

function Home() {
  return (
    <div>
      <Header />
      <div className="h-96">
        <Carousel />
      </div>
      <div className="w-screen ">
        <div className="bg-[#fff]">
          <Categories />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
