import Form from "./form";

function Footer() {
  return (
    <div className="bg-background h-20">
      <div className="flex item-center ">
        <Form />
      </div>
    </div>
  );
}

export default Footer;
