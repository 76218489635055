import { Dropdown } from "flowbite-react";
import { Flowbite } from "flowbite-react";

const customTheme = {
  button: {
    color: {
      primary: "bg-white-500 hover:bg-gray-100",
    },
  },
};

const CategoryDropdown = () => {
  const categories = [
    {
      id: 1,
      name: "Electronics",
      subcategories: [
        {
          id: 11,
          name: "TV",
          subsubcategories: ["LED", "OLED", "Plasma"],
        },
        "Washing Machines",
        "Camera",
      ],
    },
    {
      id: 2,
      name: "Computers",
      subcategories: [
        {
          id: 21,
          name: "Laptops",
          subsubcategories: ["Windows", "Mac"],
        },
        "Desktops",
        "Accessories",
      ],
    },
    {
      id: 3,
      name: "Smart Home",
      subcategories: ["Smart Speakers", "Smart Lights", "Security Cameras"],
    },
  ];

  // State to keep track of which subcategories are expanded
  // const [expandedSubcategories, setExpandedSubcategories] = useState([]);

  // Function to handle hovering over the icon
  // const handleIconHover = (categoryId, subcategoryId) => {
  //   const expanded = [...expandedSubcategories];
  //   const index = expanded.findIndex(
  //     (item) =>
  //       item.categoryId === categoryId && item.subcategoryId === subcategoryId
  //   );

  //   if (index === -1) {
  //     expanded.push({ categoryId, subcategoryId });
  //   } else {
  //     expanded.splice(index, 1);
  //   }

  //   setExpandedSubcategories(expanded);
  // };

  return (
    <div className="relative inline-block group">
      <a
        href="/"
        className="block text-button-text p-2 hover:bg-white shadow-md hover:text-[#000]"
      >
        Categories
      </a>
      <ul className="absolute left-0 top-full space-y-2 bg-white p-2 shadow-md hidden group-hover:block scale-100 z-[1000] text-black w-40">
        {categories.map((category) => (
          <li key={category.id}>
            <div className="flex flex-wrap gap-4">
              <Flowbite theme={{ theme: customTheme }}>
                <Dropdown
                  label={category.name}
                  placement="right"
                  size="sm"
                  color="primary"
                >
                  {category.subcategories &&
                    category.subcategories.length > 0 && (
                      <ul className="space-y-2 ">
                        {" "}
                        {category.subcategories.map(
                          (subcategory, subcategoryIndex) => (
                            <Dropdown.Item key={subcategoryIndex}>
                              {typeof subcategory === "object" ? (
                                <span>
                                  <a href="/">{subcategory.name}</a>{" "}
                                </span>
                              ) : (
                                subcategory
                              )}
                              {subcategory.subsubcategories &&
                                subcategory.subsubcategories.length > 0 && (
                                  <ul
                                    className={`hidden 
                                    } space-y-2 `}
                                  >
                                    {subcategory.subsubcategories.map(
                                      (subsubcategory, subsubcategoryIndex) => (
                                        <Dropdown.Item
                                          key={subsubcategoryIndex}
                                        >
                                          <a href="/"> {subsubcategory}</a>
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </ul>
                                )}
                            </Dropdown.Item>
                          )
                        )}
                      </ul>
                    )}
                </Dropdown>
              </Flowbite>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryDropdown;
